<template>
    <div class="preprint-page">
        <div class="wid1200">
            <bread-crumb :pN="pN" :childName="pageName"></bread-crumb>
        </div>
        <section class="preprint-main">

            <div class="top-banner-wrap">
                <div class="wid1200">
                    <div class="top-banner-component">

                        <div class="left-top-banner">
                            <div class="left-top-banner-bg"></div>
                            <h5>{{bannerData.title}}</h5>
                            <div>
                                <p class="banner-content" v-html="bannerData.content">
                                </p>
                                <span @click="goIntro">更多></span>
                            </div>

                        </div>
                        <div class="right-top-banner">
                            <top-banner :bannerData="imgBannerData" :pageName="pageName"></top-banner>
                        </div>
                    </div>
                </div>
            </div>
            <div class="wid1200 news">
                <div class="left-news-wrap news-wrap">
                    <div class="com-title">
                        <div>
                            <h5>预印本资讯</h5>
                        </div>
                        <div @click="goNews('yyb_zx')">
                            <span>更多<img src="../assets/img/more.png" alt="" /></span>
                        </div>
                    </div>
                    <ul class="news-content">
                        <li v-for="(item, index) in newsData" :key="index">
                            <p><a @click="goNewsDetail(item)">{{item.title}}</a></p><span>{{item.releaseDate}}</span>
                        </li>
                    </ul>
                </div>
                <div class="right-news-wrap news-wrap">
                    <div class="com-title">
                        <div>
                            <h5>预印本文章动态</h5>
                        </div>
                        <div @click="goNews('yyb_wzdt')">
                            <span>更多<img src="../assets/img/more.png" alt="" /></span>
                        </div>
                    </div>
                    <ul class="news-content">
                        <li v-for="(item, index) in articleDynamicData" :key="index">
                            <p><a  @click="goNewsDetail(item)">{{item.title}}</a></p><span>{{item.releaseDate}}</span>
                        </li>
                        <!-- <li>
                            <p>新冠疫情期间大学生网课学习的特点（预印本）</p><span>2020-10-02</span>
                        </li>
                        <li>
                            <p>新冠疫情期间大学生网课学习的特点（预印本）</p><span>2020-10-02</span>
                        </li>
                        <li>
                            <p>新冠疫情期间大学生网课学习的特点（预印本）</p><span>2020-10-02</span>
                        </li> -->
                    </ul>
                </div>
            </div>
            <div class="wid1200 article-wrap">
                <com-article :articleData="articleData" :pageName="pageName"  @pageChange="articleChange" @changeSubject="changeArticleSubject" />
            </div>
            <div class="wid1200 book-wrap">
                <com-book :bookData="bookData" :page-name="pageName" @pageChange="partnerChange" @changeSubject="changeBookSubject"/>
            </div>



            <!-- <div class="wid1200 dynamic-wrap">
                <div class="com-title">
                    <div>
                        <h5>最新资讯</h5>
                    </div>
                    <div>
                        <span>更多<img src="../assets/img/more.png" alt="" /></span>
                    </div>
                </div>
                <div class="dynamic">
                    123
                </div>
            </div> -->
        </section>

    </div>
</template>

<script>
import BreadCrumb from '../components/BreadCrumb'
import TopBanner from '../components/ComBanner'
// import TopBanner from '../components/TopBanner'
import ComArticle from '../components/ComArticle'
import ComBook from '../components/ComBook'

import img1 from '../assets/img/book.png'

export default {
    name: '',
    components: { BreadCrumb, TopBanner, ComArticle, ComBook },
    data() {
        return {
            pN: '',
            pageName: '预印本',
            newsData: [],
            articleDynamicData: [],
            bannerData: {
                title: '预印本简介',
                content: '网课是疫情期间大学课程教学的主要形式。调查表明，直播、复合 型网课是主流。大部分大学生对网课适应、基本满意，但在学习效果判断上态度犹豫。大学生普遍反映，网课在“网络速度”“师生互动”等方面需要改进。网络授课也将对大学学习的形式产生深刻影响，但它毕竟难以取代真正的大学学习生活。尽管疫情的发展还有一定不确定性，从调查结果来看，约... '
            },
            imgBannerData: [],
            articleData: {
                title: '预印本文章',
                articleList: []
            },
            bookData: {
                title: '预印本合作方',
                bookList: []
            },
            articleSubjectId: '',
            bookSubjectId: ''
        }
    },
    methods: {
        changeArticleSubject (val) {
            this.articleSubjectId = val
            this.getArticleData(1)
        },
        changeBookSubject(val) {
            //学科
            this.bookSubjectId = val
            this.getPartnerData(1)
//             /api/aloneArticle/articleClassify   
// 预印本文章列表：   /api/aloneArticle/articleList subjectId (取分类的id)

        },
        goIntro () {
            this.$router.push({path: '/preprintIntro/p', query: {pN: encodeURI(this.pageName), id: 'yyb_gyyyb'}})
        },
        goNews (id) {
            // this.$router.push({path: '/news/n', query: {pN: encodeURI(this.pageName), id: this.}})
            // this.$router.push({ path: '/news/n', query: { title: '最新资讯', id: 'sy_zxdt' } })
            this.$router.push({path: '/news/n', query: {id: id, pN: encodeURI(this.pageName)}})
        },
        goNewsDetail (item) {
            if (item.jumpUrl) {
                window.open(item.jumpUrl, '_blank')
            } else if (item.originUrl) {
                window.open(item.originUrl, '_blank')
            } else {
                this.$router.push({path: '/nD/n', query: {id: item.id, pN: encodeURI(this.pageName)}})
            }
        },


        articleChange(val) {
            this.getArticleData(val)
        },
        partnerChange(val) {
            this.getPartnerData(val)
        },
        getBannerData() {
            var _this = this
            this.$http({
                method: 'get',
                url: '/admin/api/news/list?innerCode=yyb_lb',
                params: {
                    pageNum: 1,
                    pageSize: 4
                }
            }).then(res => {
                if (res.data.code == 0) {
                    _this.imgBannerData = res.data.data.rows
                }
            })
        },
        getNewsData() {
            var _this = this
            this.$http({
                method: 'get',
                url: '/admin/api/news/list?innerCode=yyb_zx',
                params: {
                    pageNum: 1,
                    pageSize: 4
                }
            }).then(res => {
                if (res.data.code == 0) {
                    _this.newsData = res.data.data.rows
                }
            })
        },
        getArticleDynamicData() {
            var _this = this
            this.$http({
                method: 'get',
                url: '/admin/api/news/list?innerCode=yyb_wzdt',
                params: {
                    pageNum: 1,
                    pageSize: 4
                }
            }).then(res => {
                if (res.data.code == 0) {
                    _this.articleDynamicData = res.data.data.rows
                }
            })
        },
        
        getArticleData(num) {
            var _this = this
            this.$http({
                method: 'get',
                url: '/admin/api/aloneArticle/articleList',
                params: {
                    pageNum: num,
                    pageSize: 14,
                    classifyId: this.articleSubjectId
                }
            }).then(res => {
                if (res.data.code == 0) {
                    _this.articleData.articleList = res.data.data.rows
                    _this.articleData.total = res.data.data.total
                    // console.log('_this.articleData.articleList', _this.articleData.articleList)
                }
            })
        },
        getPartnerData(num) {
            var _this = this
            this.$http({
                method: 'get',
                url: '/admin/api/aloneArticle/partnerList',
                params: {
                    pageNum: num,
                    pageSize: 14,
                    classifyId: this.bookSubjectId
                }
            }).then(res => {
                if (res.data.code == 0) {
                    _this.bookData.bookList = res.data.data.rows
                    _this.bookData.total = res.data.data.total
                    // console.log('bookData', _this.bookData.bookList)
                }
            })
        },
        getInfo () {
            var _this = this
            this.$http({
                method: 'get',
                url: '/admin/api/news/list?innerCode=yyb_jj'
            }).then(res => {
                _this.bannerData.title = res.data.data.rows[0].title
                // console.log('res.data.data.rows[0]', res.data.data.rows[0])
                // _this.bannerData.content = res.data.data.rows[0].content
                // 去掉富文本中html标签和图片
                var msg = res.data.data.rows[0].content.replace(/<(p|div)[^>]*>(<br\/?>|&nbsp;)<\/\1>/gi, '\n').replace(/<br\/?>/gi, '\n').replace(/<[^>/]+>/g, '').replace(/(\n)?<\/([^>]+)>/g, '').replace(/\u00a0/g, ' ').replace(/&nbsp;/g, ' ').replace(/<\/?(img)[^>]*>/gi, '').replace(/&amp;/g,"&").replace(/&lt;/g,"<").replace(/&gt;/g,">").replace(/&#39;/g,"\'").replace(/&quot;/g,"\"").replace(/<\/?.+?>/g,"")
                _this.bannerData.content = msg
            })
        }
    },
    created() {
        if (this.$route.query.pN) {
            this.pN = decodeURI(this.$route.query.pN)
        } else {
            this.pN = ''
        }
        this.getBannerData()
        this.getNewsData()
        this.getArticleDynamicData()
        this.getInfo()
        this.getArticleData(1)
        this.getPartnerData(1)
    }
}
</script>

<style lang="scss" scoped>
.preprint-page {
    .top-banner-wrap {
        width: 100%;
        background: #cb2f3c;
        min-height: 300px;
        .top-banner-component {
            display: flex;
            justify-content: space-between;
            .left-top-banner {
                width: 380px;
                height: 250px;
                margin-top: 25px;
                padding: 18px;
                box-sizing: border-box;
                position: relative;
                z-index: 0;
                h5 {
                    font-size: 22px;
                    line-height: 22px;
                    color: #fff;
                    margin-bottom: 12px;
                }
                div {
                    z-index: 10;
                    .banner-content {
                        font-size: 14px;
                        line-height: 26px;
                        color: #fff;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 6;
                        -webkit-box-orient: vertical;
                    }
                    span {
                        font-size: 16px;
                        color: #fcbb0b;
                        float: right;
                        margin-top: 4px;
                        cursor: pointer;
                    }
                }
                .left-top-banner-bg {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 380px;
                    height: 250px;
                    background-image: url('../assets/img/bannerbg.png');
                    background-size: 100% 100%;
                    opacity: 0.4;
                    z-index: -1;
                }
            }
        }
    }
    .preprint-main {
        .news {
            display: flex;
            justify-content: space-between;
            margin-top: 30px;
            .news-wrap {
                width: 588px;
                border: 1px solid #f5f5f5;
                box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
                padding: 10px 20px 3px;
                box-sizing: border-box;
                .news-content {
                    font-size: 18px;
                    line-height: 18px;
                    margin-top: 20px;
                    li {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 25px;
                        p {
                            font-size: 18px;
                            line-height: 18px;
                            cursor: pointer;
                            position: relative;
                            padding-left: 10px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            width: 420px;
                            a {
                                color: #333;
                                font-size: 18px;
                                line-height: 18px;
                                display: block;
                                overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            }
                        }
                        p::before {
                            content: '';
                            width: 4px;
                            height: 4px;
                            border-radius: 50%;
                            background: #ffac13;
                            position: absolute;
                            left: 0;
                            top: 8px;
                        }
                        span {
                            font-size: 18px;
                            line-height: 18px;
                        }
                    }
                }
            }
            .left-news-wrap {
            }
            .right-news-wrap {
            }
        }
    }
    .article-wrap {
        padding: 10px 30px 0;
        margin: 26px auto;
        box-sizing: border-box;
        border: 1px solid #f5f5f5;
        box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
    }
    .book-wrap {
        padding: 10px 30px 0;
        margin-bottom: 26px;
        box-sizing: border-box;
        border: 1px solid #f5f5f5;
        box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
    }
}
</style>